<template>
	<div id="landOrder">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<div class="mb12">
				<span class="f12">订单状态：</span>
				<a-radio-group v-model:value="search.status" button-style="solid" @change="getLandOrderList(1,info.limit)">
					<a-radio-button value="1">租赁中</a-radio-button>
					<a-radio-button value="2">已到期</a-radio-button>
					<a-radio-button value="3">已完成</a-radio-button>
					<a-radio-button value="4">待支付</a-radio-button>
					<a-radio-button value="5">已取消</a-radio-button>
					<a-radio-button value="0">全部</a-radio-button>
				</a-radio-group>
			</div>
			<div class="mb12">
				<span class="f12">选择土地：</span>
				<a-space>
					<a-select v-model:value="search.land_id" style="width: 300px"  @change="getSpec">
						<a-select-option value="">请选择土地</a-select-option>
					    <a-select-option v-for="(item,index) in search.land" :key="index" :value="item.id">
					        {{ item.land_name}}
					    </a-select-option>
					</a-select>
				</a-space>
			</div>
			<div class="mb12">
				<span class="f12">选择地块：</span>
				<a-select v-model:value="search.spec_id" style="width: 300px">
				    <a-select-option value="">请选择地块</a-select-option>
				    <a-select-option v-for="(item,index) in search.spec" :key="index" :value="item.id">{{ item.land_num }}</a-select-option>
				</a-select>
			</div>
			<div class="mb12">
				<span class="f12">订单编号：</span>
				<a-input v-model:value="search.order_number" placeholder="订单编号" style="width: 300px"/>
			</div>
			<div class="mb12">
				<span class="f12">收货信息：</span>
				<a-input v-model:value="search.user_name" placeholder="手机号，姓名,收货地址" style="width: 300px"/>
			</div>
			<a-button style="margin-left: 60px;" type="primary" @click="getLandOrderList(1,info.limit)">
				<i class="ri-search-2-line ri-top" style="top: 1px;"></i>搜索
			</a-button>
		</div>
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-table :data-source="info.list" :pagination="false" row-key="id" :columns="[
				{title:'订单信息',dataIndex:'order',slots:{customRender:'order'}},
				{title:'地块信息',dataIndex:'land',slots:{customRender:'land'}},
				{title:'租赁人',dataIndex:'user_name',slots:{customRender:'user_name'}},
				{title:'面积价格',dataIndex:'total_price',slots:{customRender:'total_price'}},
				{title:'订单状态',dataIndex:'status',slots:{customRender:'status'}},
				{title:'时间',dataIndex:'time',slots:{customRender:'time'}},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]">
			    <template #order="{record}">
					<div><span class="f12">订单ID：</span>{{ record.id}}</div>
					<div><span class="f12">订单编号：</span> {{ record.order_number}}</div>
				</template>
				<template #land="{record}">
					<div><span class="f12">名称：</span> {{ record.land.land_name}} ({{ record.spec.land_num}})</div>
					<div><span class="f12">服务：</span> 
						<a-tag color="blue" v-if="record.ser_type > 0 && record.ser ">{{record.ser.name}}</a-tag>
						<span v-else>-</span>
					</div>
				</template>
				<template #user_name="{record}">
					<div><span class="f12">姓名：</span> {{ record.user_name}}</div>
					<div><span class="f12">电话：</span> {{ record.phone}}</div>
				</template>
				<template #total_price="{record}">
					<div><span class="f12">总价：</span> <span class="kdred">{{ record.total_price}}元</span></div>
					<div><span class="f12">面积：</span> {{ record.spec.area}}㎡</div>
				</template>
				<template #status="{record}">
					<a-tag :color="$status.getLandOrderStatus(record,true)">{{$status.getLandOrderStatus(record)}}</a-tag>
				</template>
				<template #time="{record}">
					<div><span class="f12">下单时间：</span> {{ record.create_time}}</div>
					<div><span class="f12">到期时间：</span> {{ record.expire_date}}</div>
				</template>
				<template #action="{record}">
					<router-link :to="{path:isShop == 0 ? '/land/orderDetail':'/many_store/land/orderDetail',query:{order_id:record.id}}">
						<a-button type="link">查看</a-button>
					</router-link>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getLandOrderList(info.page,e)}"
					@change="(e)=>{getLandOrderList(e,info.limit)}"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import landModel from '@/api/land.js'
export default{
	name:'page-land-rent-order',
	props:{
		isShop:{
			type:Number,
			default:0
		}
	},
	setup(){
		const _d = reactive({
			search:{
				user_name:'',
				status:"1",
				land:[],
				spec:[],
				land_id:'',
				spec_id:'',
			},
			info:{
				list:[],
				page:1,
				count:0,
				limit:10,
			},
		})
		getLandOrderList(1,_d.info.limit)
		getLand()
		function getLandOrderList(page,limit){
			landModel.getLandOrder(page,limit,_d.search,res=>{
				_d.info = { limit, ...res}
			})
		}

		function getLand(){
			landModel.getLandList(1,999,null,res=>{
				_d.search.land= res.list;
			})
		}

		function getSpec(e){
			_d.search.spec_id = ''
			_d.search.land.forEach(item=>{
				if( item.id == e ){
					_d.search.spec = item.fre_spec
				}
			})
		}

		return{
			...toRefs(_d),
			getLandOrderList,
			getLand,
			getSpec
		}
	}
}
</script>
<style lang="scss">
</style>
